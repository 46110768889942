/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Hero } from '@components/Hero'
import { Box, Flex } from '@components/Grid'
import { Text } from '@components/Text'
import { H2, H3, Topline } from '@components/Heading'
import { NewsletterSection } from '@components/NewsletterSection'
import { Figure } from '@components/Topic/Figure'

const SecondBookPage = ({ data: { content }, location }) => (
  <Layout>
    <SEO
      metadata={{
        title: content.seo.seoTitle,
        description: content.seo.seoDescription,
        pathname: location.pathname,
      }}
    />
    <Hero.Background gradient="linear-gradient(0, hsl(216, 50%, 89%), hsl(214, 100%, 97%))">
      <Hero.Shape shape="default" color="#f4f7fb" />
      <Hero>
        {content._rawHero.topline ? (
          <Hero.Topline>{content._rawHero.topline}</Hero.Topline>
        ) : null}
        <Hero.Heading content={content._rawHero.heading} />
        {content._rawHero.text ? (
          <Hero.Text content={content._rawHero.text} pb={[7, 7, 8]} />
        ) : null}
      </Hero>
    </Hero.Background>

    <Flex
      as="section"
      flexDirection="column"
      alignItems="center"
      bg="sectionBackground.light"
      px={[5, 6, 7]}
      py={[7, 8, 9]}
    >
      <Box
        width="100%"
        maxWidth="1100px"
        display="grid"
        my={[3]}
        gridTemplateColumns={['100%', null, '1fr 2fr', '1fr 1fr']}
        gridTemplateAreas={["'cover' 'content'", null, "'cover content'"]}
      >
        <Box
          display="grid"
          gridArea="cover"
          pr={[0, null, 3]}
          css={{
            justifyContent: 'stretch',
            alignContent: ['center', 'start', 'center'],
          }}
        >
          <Box p={[3, null, 0]} mb={[6, null, 0]} css={{ alignSelf: 'center' }}>
            <Box
              css={{
                maxWidth: '300px',
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <Box
                borderRadius={1}
                boxShadow="subtle"
                zIndex="10"
                css={{
                  overflow: 'hidden',
                }}
              >
                <Img fluid={content.bookCover.asset.fluid} />
              </Box>

              <Box
                position="absolute"
                width="50%"
                height="100px"
                top="95%"
                left="32px"
                display={['none', null, 'block']}
              >
                <Box position="relative">
                  <Box
                    position="absolute"
                    width="120%"
                    pb="120%"
                    top="100%"
                    left="0"
                    zIndex="1"
                    borderRadius="50% 50% 37% 63% / 59% 44% 56% 41%"
                    css={{
                      transform: 'translate3d(-50%, -50%, 0)',
                      background:
                        'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0) )',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="grid" gridArea="content" pl={[0, null, 3]}>
          <Box maxWidth="700px" mx="auto">
            {content.summaryTopline ? (
              <Topline>{content.summaryTopline}</Topline>
            ) : null}
            <H2 content={content._rawSummaryHeading} />
            <Box pl={[0, 6]} mt={[5, 6]} pb={[5]}>
              <Text mb={[5]} content={content._rawSummaryText} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>

    <Box
      as="section"
      p={[4, 5, 6]}
      bg="white"
      css={{ overflow: 'hidden' }}
      display="grid"
      gridTemplateColumns={['100%', null, '3fr 2fr']}
      gridTemplateAreas={["'figure' 'content'", null, "'figure content'"]}
    >
      <Figure
        blobType="doubleAndImage"
        variant="right"
        image={content.whereToBuyImage.asset.fluid}
      />
      <Flex
        display="flex"
        flexDirection="row"
        maxWidth="1000px"
        gridArea="content"
        px={[5]}
        py={[6]}
        zIndex={10}
        justifyContent={['center', null, 'flex-start']}
      >
        <Box>
          <H3 content={content._rawWhereToBuyHeading} color="blue.800" mb={5} />
          <Text content={content._rawWhereToBuy} />
        </Box>
      </Flex>
    </Box>

    <NewsletterSection />
  </Layout>
)

export const query = graphql`
  query SecondBookPage {
    content: sanitySecondBookPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      bookCover {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      whereToBuyImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      summaryTopline
      _rawSummaryHeading
      _rawSummaryText
      _rawWhereToBuyHeading
      _rawWhereToBuy
    }
  }
`

export default SecondBookPage
