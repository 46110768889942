import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'

import { Flex } from '@components/Grid'
import styled from '@style'

const Container = styled(Flex).attrs({
  'aria-hidden': 'true',
})`
  overflow: hidden;
`

const Blob = ({ fluid, children, ...props }) => (
  <Container {...props}>
    {fluid && (
      <Img
        fluid={fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
    )}
    {children}
  </Container>
)

Blob.displayName = 'Blob'

Blob.defaultProps = {
  fluid: null,
  children: null,
}

Blob.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fluid: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export { Blob }
