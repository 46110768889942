import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Box } from '@components/Grid'
import { Blob } from '@components/Blob'
import styled from '@style'

const Wrapper = styled(Box)`
  display: grid;
  justify-items: stretch;
  align-items: center;
  grid-area: figure;
`

const blobPositions = {
  left: {
    small: ["'... blob ...'", null, "'blob ...'"],
    smallAndImage: ["'... blob ...' ", null, "'blob ...'"],
    large: "'... ... ...' '... ... ...' '... ... blob'",
    largeAndImage: "'... ... ...' '... ... ...' '... ... blob'",
    double: "'... ... ...' '... blob bgBlob' '... ... ...'",
    doubleAndImage: "'... ... ...' '... blob bgBlob' '... ... ...'",
  },
  right: {
    small: ["'... blob ...' ", null, "'... blob'"],
    smallAndImage: ["'... blob ...' ", null, "'... blob'"],
    large: "'... ... ...' '... ... ...' 'blob ... ...'",
    largeAndImage: "'... ... ...' '... ... ...' 'blob ... ...'",
    double: "'... ... ...' 'bgBlob blob ...' '... ... ...'",
    doubleAndImage: "'... ... ...' 'bgBlob blob ...' '... ... ...'",
  },
}

const blobSizes = {
  small: { width: '80%', height: '80%', py: '40%' },
  smallAndImage: { width: '80%', height: '80%', py: '40%' },
  large: {
    display: ['none', null, 'flex'],
    position: 'absolute',
    width: '150%',
    p: '150%',
    marginLeft: '-100%',
  },
  largeAndImage: {
    display: ['none', null, 'flex'],
    position: 'absolute',
    width: '150%',
    p: '150%',
    marginLeft: '-100%',
  },
  double: {
    blob: {
      display: ['none', null, 'inline'],
      position: 'absolute',
      zIndex: '1',
      width: '150%',
      pb: '150%',
      ml: '-25%',
    },
    bgBlob: {
      position: 'absolute',
      width: '200%',
      p: '200%',
      marginLeft: '-150%',
    },
  },
  doubleAndImage: {
    blob: {
      display: ['none', null, 'inline'],
      position: 'absolute',
      zIndex: '1',
      width: '150%',
      pb: '150%',
      ml: '-25%',
    },
    bgBlob: {
      position: 'absolute',
      width: '200%',
      p: '200%',
      marginLeft: '-150%',
    },
  },
}

const Figure = ({ image, variant, blobType }) => {
  switch (blobType) {
    case 'small':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" alignItems="center">
            <Blob
              {...blobSizes[blobType]}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0))',
              }}
            />
          </Flex>
        </Wrapper>
      )

    case 'smallAndImage':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" alignItems="center">
            <Blob
              {...blobSizes[blobType]}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              fluid={image}
            />
          </Flex>
        </Wrapper>
      )

    case 'large':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" maxHeight="100%" alignItems="center">
            <Blob
              {...blobSizes[blobType]}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0))',
              }}
            />
          </Flex>
        </Wrapper>
      )

    case 'largeAndImage':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" maxHeight="100%" alignItems="center">
            <Blob
              {...blobSizes[blobType]}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              fluid={image}
            />
          </Flex>
        </Wrapper>
      )

    case 'double':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" maxHeight="10px" alignItems="center">
            <Blob
              {...blobSizes[blobType].blob}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(218,30%,70%, 0.7), hsla(218,25%,60%, 1.0))',
              }}
            />
          </Flex>
          <Flex gridArea="bgBlob" maxHeight="10px" alignItems="center">
            <Blob
              {...blobSizes[blobType].bgBlob}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0))',
              }}
            />
          </Flex>
        </Wrapper>
      )

    case 'doubleAndImage':
      return (
        <Wrapper gridTemplateAreas={blobPositions[variant][blobType]}>
          <Flex gridArea="blob" maxHeight="10px" alignItems="center">
            <Blob
              {...blobSizes[blobType].blob}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              fluid={image}
            />
          </Flex>
          <Flex gridArea="bgBlob" maxHeight="10px" alignItems="center">
            <Blob
              {...blobSizes[blobType].bgBlob}
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0))',
              }}
            />
          </Flex>
        </Wrapper>
      )

    default:
      return null
  }
}

Figure.defaultProps = {
  image: null,
  variant: null,
  blobType: null,
}

Figure.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any,
  variant: PropTypes.oneOf(['left', 'right']),
  blobType: PropTypes.oneOf([
    'small',
    'smallAndImage',
    'large',
    'largeAndImage',
    'double',
    'doubleAndImage',
  ]),
}

export { Figure }
